import React from "react";
import styled from "styled-components";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Button from "../components/button";
import Background from "../components/graphics/Background";
import FullBody from "../components/graphics/FullBody";

const Container = styled.div`
  width: 100vw;
  text-align: center;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;
const AbsoluteContainer = styled.div`
  position: absolute;
  top: ${({ top }) => top || `30px`};
  left: ${({ left }) => left || `20px`};
  ${({ fullWidth }) =>
    fullWidth &&
    `
  width: 100vw;
`}
  ${({ zIndex }) =>
    zIndex &&
    `
  z-index: ${zIndex};
`}
`;
const BackgroundContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  z-index: -10;
  ${({ backdrop }) =>
    backdrop &&
    `
  opacity: 0.05
`}
`;
const Box = styled.div`
  height: 700px;
  display: flex;
  > div {
    align-self: flex-end;
  }
`;
const SceneContainer = styled.div`
  display: flex;
  position: relative;
  margin: 60px auto 0 auto;
  z-index: 10;
`;

const Content = ({ location }) => {
  const { body: initialBody } = location.state || {};
  const [body, setBody] = React.useState(initialBody || {});
  const handleSaveState = React.useCallback(
    (part, value) => {
      setBody((prev) => ({
        ...prev,
        [part]: value,
      }));
    },
    [setBody]
  );
  return (
    <Layout>
      <SEO title="Dress Up 4 Santa: Dress Up" />
      <Container>
        <BackgroundContainer>
          <Background />
        </BackgroundContainer>

        <AbsoluteContainer zIndex={11}>
          <Button to="/start">Back</Button>
        </AbsoluteContainer>

        <ContentContainer>
          <SceneContainer>
            <Box>
              <FullBody
                width={360}
                editable
                initialValues={initialBody}
                handleSaveState={handleSaveState}
              />
            </Box>
          </SceneContainer>
        </ContentContainer>
        <div
          style={{
            position: "absolute",
            right: 30,
            top: "35%",
            textAlign: "center",
          }}
        >
          <Button to="/save" state={{ body }} fontSize="50px">
            I'm Ready!
          </Button>
        </div>
      </Container>
    </Layout>
  );
};
export default Content;
