import { Link } from "gatsby"
import styled, {keyframes} from 'styled-components';

const blink = keyframes`
0%{
  color: #fff
}
50% {
  color: #ccc
}
100%{
  color: #fff
}
`
const Button = styled(Link)`
box-shadow: 5px 5px 0 #ae125b;
font-size: ${({ fontSize}) => fontSize || '35px'};
text-decoration: none;
font-family: 'Patrick Hand', sans-serif;
padding: 10px 40px;
border-radius: 40px;
color: #fff;
border: 0;
background: #e60e7a;
outline: none;
cursor: pointer;
&:hover {
  box-shadow: 1px 2px 0 #ae125b;
  transform: translate(3px, 3px);
  animation: ${blink} 0.25s linear infinite;
}
`;

export default Button